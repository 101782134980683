<template>
  <div
    class="py-4 px-4"
    style="background-color: white;"
  >
    <v-dialog 
      v-model="dialogAlert"
      width="500"
      @click:outside="clear"
    >
      <confirm-alert 
        :question="msgAlert" 
        @answer="closeAlert" 
      />
    </v-dialog>
    <h2 
      class="py-2 px-2 background-element-primary"
      style="border-radius: 5px; color: white;"
    >
      Cancelaciones
    </h2>
    <v-row
      v-if="!loading"
      class="py-4"
    >
      <v-col
        v-for="tableData in tablesData"
        :key="tableData.id"
        cols="12"
        md="12"
      >
        <dashboard-table
          :tableData="tableData"
          @action1="updateCancellation"
        ></dashboard-table>
      </v-col>
    </v-row>
    <component-loader v-else />
  </div>

</template>

<script>
import { ApiAuth } from  '@/api_utils/axios-base'
import { ordersMixin } from '@/mixins/orders.js'
import { objectsMixin } from '@/mixins/objectsValidations.js'
import { mapState } from 'vuex'
import componentLoader from '@/components/ComponentLoader'

export default {
  metaInfo: {
    title: 'Cancelaciones de órdenes - Dashboard'
  },
  mixins: [ordersMixin, objectsMixin],
  components: {
    componentLoader,
    dashboardTable: () => import('@/components/utils/Tables/DashboardTable'),
    ConfirmAlert: () => import('@/components/utils/alerts/ConfirmAlert'),
  },
  data() {
    return {
      tablesData: [
        {
          id: 1,
          tableName: "Solicitudes de clientes",
          headers: [
            { text: 'Número de orden', value: 'orden.numero_orden', sortable: false },
            { text: 'Estatus cancelación', value: 'estatus_cancelacion.dato', sortable: false },
            { text: 'Cliente', value: 'orden.cliente.datos_personales.primer_nombre', sortable: false },
            { text: 'Motivo', value: 'motivo_cancelacion.dato', sortable: false },
            { text: 'Comentarios', value: 'comentarios', sortable: false },
            { text: 'Acciones', value: 'actions', sortable: false },
          ],
          items: []
        },
        {
          id: 2,
          tableName: "Solicitudes de distribuidores",
          headers: [
            { text: 'Número de orden', value: 'orden.numero_orden', sortable: false },
            { text: 'Estatus cancelación', value: 'estatus_cancelacion.dato', sortable: false },
            { text: 'Cliente', value: 'orden.cliente.datos_personales.primer_nombre', sortable: false },
            { text: 'Motivo', value: 'motivo_cancelacion.dato', sortable: false },
            { text: 'Comentarios', value: 'comentarios', sortable: false },
            { text: 'Acciones', value: 'actions', sortable: false },
          ],
          items: []
        },
      ],
      orders: [],
      cancellationSelected: {},
      loading: false,
    }
  },
  watch: {
    cancellationSelected(val) {
      if ( this.objectHasNulls(val) ) {
        // console.log('Tiene nulls');
        if (val.aprobador === null && val.id !== null) {
          this.updateCancellationStatus()
        }
      } else {
        // console.log('No tiene null');
        if (val.estatus_cancelacion.dato === 'En Espera') {
          this.updateCancellationStatus()
        } else {
          this.dialogAlert = true
        }
        
      }
    }
  },
  created() {
    this.allOrders()
  },
  computed: {
    ...mapState('usuario', ['userData']),
    msgAlert() {
      return this.cancellationSelected.estatus_cancelacion 
        && this.cancellationSelected.estatus_cancelacion.dato === 'Cancelado' 
          ? 'La solicidud ya ha sido rechazada'
          : 'La solicitud ya ha sido aprobada'
    },
  },
  methods: {
    async allOrders() {
      try {
        this.loading = true
        this.cancellations = []
        const distributorId = this.userData.distribuidorId
        let response = await ApiAuth.get(`/reportes/api/ordenes-por-distribuidor/?id_distribuidor=${distributorId}`)
        let ordenes = await response.data
        // console.log('ordenes: ', ordenes);

        const detailsResponse = await ApiAuth.get(`/checkout/api/detalle-ordenes-distribuidor/?id_distribuidor=${distributorId}`)
        const detailsData = await detailsResponse.data
        // console.log('detailsData: ', detailsData);

        this.orders = this.addDetailsToOrders(ordenes, detailsData)
        // console.log('ordenes con detalles: ', ordenes);

        for (const order of this.orders) {
          if (order.estatus_orden.dato === 'Cancelada') {
            const cancellation = await this.getOrderCancellation(order, ApiAuth)
            if (cancellation) {
              cancellation.orden = {...order}
              this.cancellations.push(cancellation)
            }
          }
        }
        // console.log('Cancelaciones con orders? ', this.cancellations);
        this.tablesData[0].items = this.cancellations.filter(cancellation => cancellation.solicitado_por_cliente === true)
        this.tablesData[1].items = this.cancellations.filter(cancellation => cancellation.solicitado_por_cliente === false)
      } catch(error) {
        console.error("error getting orders", error)
      } finally {
        this.loading = false
      }
    },
    updateCancellation(item) {
      // console.log('updateCancellation: ', item);
      this.cancellationSelected = {...item}
    },
    async updateCancellationStatus() {
      try {
        const response = await ApiAuth.post('/checkout/actualizar-estatus-cancelaciones', {
          id_cancelacion: this.cancellationSelected.id,
          estatus: this.cancellationSelected.actualizacion_estatus
        })
        const responseData = await response.data
        // console.log('response update status', responseData);

        this.updateApprover()
      } catch (error) {
        console.error('Error actualizando estatus de cancelacion', error);
      }
    },
    async updateApprover() {
      try {
        const response = await ApiAuth.post('/checkout/actualizar-aprobador-cancelacion', {
          id_cancelacion: this.cancellationSelected.id,
          id_usuario_aprobador: this.userData.id
        })
        const responseData = await response.data 
        // console.log('response approver: ', responseData); 

        this.allOrders()
      } catch (error) {
        console.log('error al establecer aprobador: ', error);  
      }
      
    },
  },
}
</script>

<style>
</style>